<template>
  <div class="pain-difficult-item">
    <div class="types-img">
      <img :src="item.img">
    </div>
    <div class="types-title">
      {{ item.title }}
    </div>
    <div class="types-content">
      {{ item.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        img: '',
        title: '',
        content: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pain-difficult-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 300px;
  padding: 0 56px 0;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 6px 15px 1px rgba(0, 50, 124, 0.1);
  border-radius: 8px 8px 8px 8px;
  &:not(:last-child) {
    margin-right: 60px;
  }
  .types {
    &-img {
      width: 86px;
      height: 82px;
      margin-bottom: 33px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    &-title {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 16px;
    }
    &-content {
      font-size: 14px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #6d7281;
      line-height: 20px;
      text-align: center;
    }
  }
}
</style>
