<template>
  <!-- 新闻轮播图 -->
  <div
    class="banner100"
    v-loading="loading"
    v-if="data.length > 1"
  >
    <div class="banner-box-swiper">
      <swiper
        :options="swiperOptions"
        v-if="!loading"
      >
        <swiper-slide
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="banner">
            <h3 class="title">
              {{ item.title }}
            </h3>
            <p class="text">
              {{ item.content }}
            </p>
          </div>
        </swiper-slide>
        <!-- 条 -->
        <template #pagination>
          <div class="swiper-pagination" />
        </template>
      </swiper>
    </div>
  </div>
  <div
    v-else
    class="banner100"
  >
    <div
      class="banner"
      v-for="(item, index) in data"
      :key="index"
    >
      <h3 class="title">
        {{ item.title }}
      </h3>
      <p class="text">
        {{ item.content }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [
        {
          id: '',
          title: '',
          content: '',
          imgUrl: ''
        }
      ]
    },
    interval: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      loading: false,
      // #region 轮播图配置
      swiperOptions: {
        autoplay: {
          delay: this.interval
        },
        speed: 1000,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
      // #endregion
    }
  }
}
</script>

<style lang="less" scoped>
.banner100 {
  position: relative;
  width: 100%;
  height: 450px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: calc(100vw - var(--scrollbar));
    min-width: 1200px;
    background: url('/static/img/theSolution/service-banner.png') no-repeat
      center;
  }
  .banner {
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 36px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2d3460;
      line-height: 48px;
    }
    .text {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #8087ac;
      line-height: 20px;
      margin-top: 27px;
      text-align: center;
      max-width: 880px;
    }
  }
  &-swiper {
    height: 100%;
    width: 100%;
  }
  /deep/ .swiper-container {
    height: 100%;
    .swiper-pagination {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    .swiper-pagination-bullet {
      border-radius: 3px;
      height: 5px;
      width: 40px;
      background: #fff;
      opacity: 1;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .swiper-pagination-bullet-active {
      background: @mainColor;
    }
  }
}
</style>
