<template>
  <div
    class="pain-difficult"
    id="difficult"
  >
    <v-subtitle
      zh="痛点难点"
      en="The pain difficult"
      type="mini"
    />
    <div class="pain-difficult-types">
      <v-type
        v-for="(item, index) in typesList3"
        :key="index"
        :item="item"
      />
    </div>
    <div class="pain-difficult-types">
      <v-type
        v-for="(item, index) in typesList6"
        :key="index"
        :item="item"
      />
    </div>
    <div class="solution-download">
      <div class="l">
        <div class="l-title">
          {{ data.solution.title }}
        </div>
        <div class="l-content">
          {{ data.solution.content }}
        </div>
        <div class="btn">
          <div class="btn-content">
            免费下载
          </div>
        </div>
      </div>
      <div class="r">
        <img :src="data.solution.img">
      </div>
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vType from './type.vue'
export default {
  components: {
    vSubtitle,
    vType
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    typesList3 () {
      return this.data.typeList.slice(0, 3)
    },
    typesList6 () {
      return this.data.typeList.slice(3, 6)
    }
  },
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.pain-difficult {
  margin-bottom: 90px;
  &-types {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
  .solution-download {
    width: 100%;
    height: 177px;
    background: linear-gradient(90deg, #2b83f7 0%, #2fd5ff 100%);
    border-radius: 6px 6px 6px 6px;
    position: relative;
    display: flex;
    &::before {
      content: '';
      width: 357px;
      height: 62px;
      position: absolute;
      right: 45px;
      bottom: 0;
      background: url('/static/img/theSolution/solution-download.png') no-repeat
        center;
    }
    .l {
      margin: 15px 34px 15px 49px;
      &-title {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        margin-bottom: 12px;
      }
      &-content {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        margin-bottom: 16px;
      }
      .btn {
        &-content {
          padding: 7px 32px;
          background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
          box-shadow: 0px 3px 6px 1px rgba(73, 120, 250, 0.19);
          border-radius: 4px 4px 4px 4px;
          border: 3px solid #ffffff;
          display: inline-block;
          cursor: pointer;
          // 禁止文字选中
          user-select: none;
          font-size: 16px;
          font-weight: 400;
          color: #018ede;
          line-height: 21px;
          box-sizing: border-box;
          &:hover {
            animation: 0.1s ease-in discoloration;
            opacity: 0.9;
          }
        }
      }
    }
    .r {
      img {
        height: 100%;
      }
    }
  }
}
</style>
