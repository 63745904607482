<template>
  <div class="service-solution">
    <v-banner :data="swiperData" />
    <v-nav-bar :data="navBarList" />
    <v-solution-overview :data="solutionOverviewData" />
    <v-pain-difficult :data="painDifficultData" />
    <v-functional-framework :imgurl="functionalFramework" />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        scheme-advantages-bg
      "
      id="advantages"
    >
      <v-scheme-advantages
        :typeslist1="typesList1"
        :typeslist2="typesList2"
      />
    </div>
    <v-customer-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  企业招标采购综合服务解决方案
import vNavBar from './components/navBar/index.vue'
import vBanner from './components/banner/index.vue'
import vSolutionOverview from './components/solutionOverview/index.vue'
import vPainDifficult from './components/painDifficult/index.vue'
import vFunctionalFramework from './components/functionalFramework/index.vue'
import vSchemeAdvantages from './components/schemeAdvantages/index.vue'
import vCustomerStories from './components/customerStories/index.vue'
// vPainDifficult
export default {
  components: {
    vBanner,
    vNavBar,
    vSolutionOverview,
    vPainDifficult,
    vFunctionalFramework,
    vSchemeAdvantages,
    vCustomerStories
  },
  computed: {
    typesList1 () {
      return this.schemeAdvantages.slice(0, 3)
    },
    typesList2 () {
      return this.schemeAdvantages.slice(3, 5)
    }
  },
  data () {
    return {
      swiperData: [],
      navBarList: [
        {
          name: '方案详情',
          ref: 'overview'
        },
        {
          name: '难点痛点',
          ref: 'difficult'
        },
        {
          name: '功能框架',
          ref: 'framework'
        },
        {
          name: '方案优势',
          ref: 'advantages'
        },
        {
          name: '客户案例',
          ref: 'stories'
        }
      ],
      solutionOverviewData: {
        subTitleData:
          '完善与提高农业生产过程中各个环节的信息化水平的基础上，构建统一业务支撑与信息管理平台，完成对科学育种、科学生产、数字物流、精准销售等各个环节的有效管控',
        list: []
      },
      painDifficultData: {
        typeList: [
          {
            img: '/static/img/theSolution/c-7.png',
            title: '农业生产现状',
            content:
              '在生产环节，农业“靠天吃饭”局面未能得到根本改变，科技支撑不足。导致产品质量不高，经济效益低下等突出问题。'
          },
          {
            img: '/static/img/theSolution/c-5.png',
            title: '农业市场现状',
            content:
              '缺乏对农业相关产品的市场供需数据分析，无法引导农民进行调整农业产生，导致农业产品的供需波澜起伏。'
          },
          {
            img: '/static/img/theSolution/c-1.png',
            title: '农业物流现状',
            content:
              '由于农村道路状况差，各种运输、仓储设备和加工以及保鲜技术都相对落后，导致较大农产品物流损耗，不但延长了物流时间，还增加了成本。'
          },
          {
            img: '/static/img/theSolution/c-10.png',
            title: '农业管理现状',
            content:
              '养殖、种植的农产品体量大，农户缺少向政府部门报备自家农产品情况的平台，导致政府部门对管辖区域内的农产品实际情况没有准确的信息。'
          },
          {
            img: '/static/img/theSolution/c-11.png',
            title: '农业融资现状',
            content:
              '农业实际融资渠道较少。我国农业融资在进行中面临这一现实困境，农业融资渠道是否畅通也会对融资成功率产生直接影响。'
          }
        ],
        solution: {
          title: '《数字农业综合服务解决方案》',
          content:
            '完善与提高农业生产过程中各个环节的信息化水平的基础上，构建统一业务支撑与信息管理平台，完成对科学育种、科学生产、数字物流、精准销售等各个环节的有效管控。',
          img: '/static/img/theSolution/digital-agriculture.png'
        }
      },
      functionalFramework: 'static/img/theSolution/functional-framework.png',
      schemeAdvantages: [
        {
          img: '/static/img/theSolution/e-1.png',
          title: '业务协同',
          content:
            '整合了科研、农委、企业等相关机构的应用平台，实现了跨部门、跨区域的数据共享利用与应用协同'
        },
        {
          img: '/static/img/theSolution/e-2.png',
          title: '实时交易数据',
          content:
            '发售产品均与农业生产者在平台中登记的资产信息挂钩，避免出现虚假信息，保障卖买双方权益，实现“手机做交易”。'
        },
        {
          img: '/static/img/theSolution/e-3.png',
          title: '建立资源上云机制',
          content:
            '农户和企业可以通过平台实现手机管资产、手机管生产、手机做交易、手机定服务。'
        },
        {
          img: '/static/img/theSolution/e-4.png',
          title: '拓宽金融渠道',
          content:
            '平台与银行合作，为农业养殖主体及加工主体提供闭环的在线融资服务，有效降低银行的融资风险。'
        },
        {
          img: '/static/img/theSolution/e-5.png',
          title: '物流信息共享',
          content:
            '建立“订单农业”模式，农户与购买者直接对话，减少流通环节，提高农民收入。'
        }
      ],
      caseList: []
    }
  },
  methods: {
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['数字农业']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取案例 - 数字农业
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['数字农业']
      }
      this.$api
        .getCaseInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.caseList = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取方案 - 数字农业
    getProgrammeList () {
      const params = {
        parentId: this.$siteTypes.programmeTypes['数字农业']
      }
      this.$api
        .getProgrammeInfo(params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return console.error(res)
          }
          this.solutionOverviewData.list = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
    this.getProgrammeList()
  }
}
</script>

<style lang="less" scoped>
.service-solution {
  .banner100 {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: calc(100vw - var(--scrollbar));
      min-width: 1200px;
      background: url('/static/img/theSolution/digitalAgriculture.png')
        no-repeat center;
    }
    .banner {
      height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 36px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #2d3460;
        line-height: 48px;
      }
      .text {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #8087ac;
        line-height: 20px;
        margin-top: 27px;
        text-align: center;
      }
    }
  }
  .banner100vw();
  .scheme-advantages-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
